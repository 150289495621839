import { createSlice } from "@reduxjs/toolkit";
import { callApi } from '../Helper/helper'
import { apiFail, apiSuccess } from "./mainSlice";
const userSlice = createSlice({
    name:"userSlice",
    initialState:{
        userList:null,
        userEdit:{
            name:'',
            email:'',
            phone_no:'',
            status:''
        },
        vendorList:null,
        vendorEdit:{
            name:'',
            email:'',
            phone_no:'',
            commission:'',
            status:''
        }
    },
    reducers:{
        setUserList:(state,action)=>{
            state.userList=action.payload
        },
        setUserEditDetail:(state,action)=>{
            state.userEdit=action.payload.data
        },
        unSetUserEditDetail:(state,action)=>{
            state.userEdit={
                name:'',
                email:'',
                phone_no:'',
                status:''
            }
        },
        setVendorList:(state,action)=>{
            state.vendorList=action.payload
        },
        setVendorEditDetail:(state,action)=>{
            state.vendorEdit=action.payload.data
        },
        unSetVendorEditDetail:(state,action)=>{
            state.vendorEdit={
                name:'',
                email:'',
                phone_no:'',
                commission:'',
                status:''
            }
        }
    }
})
const { setUserList, setUserEditDetail, unSetUserEditDetail, setVendorList, setVendorEditDetail, unSetVendorEditDetail } = userSlice.actions
export default userSlice.reducer
export const getUserList = (page = 1,filterData = {}) => async dispatch => {
    try {
        let payload = Object.assign({},{page:page},filterData)
        let res = await callApi('post','/user/customer/list',payload)
        dispatch(unSetUserEditDetail())
        dispatch(setUserList(res.data.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const getUserEditDetail = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/customer/edit',value)
        dispatch(setUserEditDetail(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const userAdd = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/customer/add',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
       dispatch(apiFail(error)) 
    }
}
export const userUpdate = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/customer/update',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
       dispatch(apiFail(error)) 
    }
}
export const getVendorList = (page = 1,filterData = {}) => async dispatch => {
    try {
        let payload = Object.assign({},{page:page},filterData)
        let res = await callApi('post','/user/vendor/list',payload)
        dispatch(unSetVendorEditDetail())
        dispatch(setVendorList(res.data.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const getVendorEditDetail = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/vendor/edit',value)
        dispatch(setVendorEditDetail(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const vendorAdd = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/vendor/add',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
       dispatch(apiFail(error)) 
    }
}
export const vendorUpdate = (value) => async dispatch => {
    try {
        let res = await callApi('post','/user/vendor/update',value)
        dispatch(apiSuccess(res.data))
    } catch (error) {
       dispatch(apiFail(error)) 
    }
}