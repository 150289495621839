import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
const SettingsPanel = () => {
  const closeRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.remove('open');
  }
  return (
    <div>
      <div id="right-sidebar" className="settings-panel right-sidebar">
        <i className="settings-close mdi mdi-close"  onClick={closeRightSidebar}></i>
        <Tabs defaultActiveKey="CHATS" className="bg-gradient-primary" id="uncontrolled-tab-example">
        <Tab eventKey="CHATS" title="CHATS">
            <div>
            <div className="d-flex align-items-center justify-content-between border-bottom">
                <p className="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">FRIENDS</p>
                <small className="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 font-weight-normal">See All</small>
            </div>
            <ul className="chat-list">
                <li className="list active">
                <div className="profile"><img src={ require("../../assets/images/faces/face1.jpg")} alt="profile" /><span className="online"></span></div>
                <div className="info">
                    <p>Thomas Douglas</p>
                    <p>Available</p>
                </div>
                <small className="text-muted my-auto">19 min</small>
                </li>
                <li className="list">
                <div className="profile"><img src={ require("../../assets/images/faces/face2.jpg")} alt="profile" /><span className="offline"></span></div>
                <div className="info">
                    <div className="wrapper d-flex">
                    <p>Catherine</p>
                    </div>
                    <p>Away</p>
                </div>
                <div className="badge badge-success badge-pill my-auto mx-2">4</div>
                <small className="text-muted my-auto">23 min</small>
                </li>
                <li className="list">
                <div className="profile"><img src={ require("../../assets/images/faces/face3.jpg")} alt="profile" /><span className="online"></span></div>
                <div className="info">
                    <p>Daniel Russell</p>
                    <p>Available</p>
                </div>
                <small className="text-muted my-auto">14 min</small>
                </li>
                <li className="list">
                <div className="profile"><img src={ require("../../assets/images/faces/face4.jpg")} alt="profile" /><span className="offline"></span></div>
                <div className="info">
                    <p>James Richardson</p>
                    <p>Away</p>
                </div>
                <small className="text-muted my-auto">2 min</small>
                </li>
                <li className="list">
                <div className="profile"><img src={ require("../../assets/images/faces/face5.jpg")} alt="profile" /><span className="online"></span></div>
                <div className="info">
                    <p>Madeline Kennedy</p>
                    <p>Available</p>
                </div>
                <small className="text-muted my-auto">5 min</small>
                </li>
                <li className="list">
                <div className="profile"><img src={ require("../../assets/images/faces/face6.jpg")} alt="profile" /><span className="online"></span></div>
                <div className="info">
                    <p>Sarah Graves</p>
                    <p>Available</p>
                </div>
                <small className="text-muted my-auto">47 min</small>
                </li>
            </ul>
            </div>
        </Tab>
        </Tabs>
      </div>
    </div>
  )
}
export default SettingsPanel
