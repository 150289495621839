import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'

const categorySlice = createSlice({
    name:"Category",
    initialState:{
        list:{
            isLoading:true,
            data:[]
        },
        detail:{
            title:"",
            image:"",
            status:""
        },
    },
    reducers:{
        listing:(state,action)=>{
            state.list = {
                isLoading:false,
                data: action.payload.data
            }
        },
        setDetail:(state,action) =>{
            state.detail =action.payload.data
        },
        unSetDetail:(state,action)=>{
            state.detail = {
                title:"",
                image:"",
                status:""
            }
        }
    }
})

export default categorySlice.reducer
const { listing,setDetail,unSetDetail } = categorySlice.actions

export const getList = (value) => async dispatch =>{
    try {
        const res = await callApi('post','/category/list',value)
        dispatch(unSetDetail())
        dispatch(listing(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const addCategory = (value) => async dispatch =>{
    try {
        const res = await callApi('post','/category/add',value,{'Content-Type': 'multipart/form-data'})
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const getEditCategory = (value) => async dispatch => {
    try {
        let res = await callApi('post','/category/edit',value)
        dispatch(setDetail(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const updateCategory = (value) => async dispatch => {
  try {
      let res = await callApi('post','/category/update',value,{'Content-Type': 'multipart/form-data'})
      dispatch(apiSuccess(res.data))
  } catch (error) {
      dispatch(apiFail(error))
  }
};

