import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../Redusers/authSlice';
// Import third party Css
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
const App = ({location,history}) => {
  const dispatch = useDispatch()
  const authUser = useSelector(state=>state.auth.loggedInUser)
  const [isFullPageLayout, setIsFullPageLayout] = useState(false)

/* common component start */
  let navbarComponent = !isFullPageLayout ? <Navbar authUser={authUser}/> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar authUser={authUser}/> : '';
  let SettingsPanelComponent = !isFullPageLayout ? <SettingsPanel/> : '';
  let footerComponent = !isFullPageLayout ? <Footer/> : '';
/* common component end */

  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login','/forgot','/reset-password','/404'];
    if (fullPageLayoutRoutes.includes(location.pathname)) {
      setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
    } else {
      setIsFullPageLayout(false)
      document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
    }
  }

  useEffect(()=>{
    const nonAuthRoute = ['/404','/login','/forgot','/reset-password']
    if (localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN) && !authUser.isLoading) {
      if(!nonAuthRoute.includes(location.pathname) && authUser.data._id===''){
        history.push('/login')
      }else if(nonAuthRoute.includes(location.pathname) && authUser.data._id!==''){
        history.push('/')
      }
    }else{
      if(localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)){
        dispatch(getProfile())
      }else{
        if(!nonAuthRoute.includes(location.pathname)){
          history.push('/login')
        }
      } 
    }
  },[authUser])
  useEffect(() => {
    onRouteChanged()
  }, [location])
  return (
    <div className="container-scroller">
      <ToastContainer
        position="top-right"
        // hideProgressBar={true}
        autoClose={3000}
        closeOnClick
        draggable
      />
      { navbarComponent }
      <div className="container-fluid page-body-wrapper">
        { sidebarComponent }
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes/>
            { SettingsPanelComponent }
          </div>
          { footerComponent }
        </div>
      </div>
    </div>
  );
}
  
export default withRouter(App);
