import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Redusers/authSlice";
import mainSlice from "./Redusers/mainSlice";
import userSlice from "./Redusers/userSlice";
import categorySlice from "./Redusers/categorySlice";
import serviceSlice from "./Redusers/serviceSlice";
import cmsSlice from "./Redusers/cmsSlice";
const store = configureStore({
    reducer:{
        main:mainSlice,
        auth:authSlice,
        user:userSlice,
        category:categorySlice,
        service:serviceSlice,
        cms:cmsSlice
    }
})
export default store