import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'

const cmsSlice = createSlice({
    name:"Cms",
    initialState:{
        list:{
            isLoading:true,
            data:[]
        },
        detail:{
            loading:true,
            data:{
                title:"",
                slug:"",
                content:""
            }
        },
        testiomonialList:{
            isLoading:true,
            data:[]
        },
        testiomonialDetail:{
            loading:true,
            data:{
                _id:"",
                userName:"",
                position:"",
                description:"",
                rating:"",
                image:"",
            }
        }
    },
    reducers:{
        listing:(state,action)=>{
            state.list = {
                isLoading:false,
                data: action.payload.data
            }
        },
        setDetail:(state,action) =>{
            state.detail ={
                loading:false,
                data:action.payload.data
            } 
        },
        unSetDetail:(state,action)=>{
            state.detail = {
                loading:true,
                data:{
                    title:"",
                    slug:"",
                    content:""
                }
            }
        },
        testimonialListing:(state,action)=>{
            state.testiomonialList = {
                isLoading:false,
                data: action.payload.data
            }
        },
        setTestimonialDetail:(state,action) =>{
            state.testiomonialDetail ={
                loading:false,
                data:action.payload.data
            } 
        },
        unSetTestimonialDetail:(state,action)=>{
            state.testiomonialDetail = {
                loading:true,
                data:{
                    _id:"",
                    userName:"",
                    position:"",
                    description:"",
                    rating:"",
                    image:"",
                }
            }
        }
    }
})

export default cmsSlice.reducer
const { listing,setDetail,unSetDetail , testimonialListing, setTestimonialDetail, unSetTestimonialDetail} = cmsSlice.actions

export const getList = (filterData) => async dispatch =>{
    try {
        const res = await callApi('post','/cms/list',filterData)
        dispatch(unSetDetail())
        dispatch(listing(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const getDetail = (value) => async dispatch =>{
    try {
        const res = await callApi('post','/cms/detail',value)
        dispatch(setDetail(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const updateCms = (value) => async dispatch => {
  try {
      let res = await callApi('post','/cms/update',value)
      dispatch(apiSuccess(res.data))
  } catch (error) {
      dispatch(apiFail(error))
  }
};

// Testimonial function start
export const getTestimonialList = () => async dispatch =>{
    try {
        const res = await callApi('post','/cms/testimonial/list',{})
        dispatch(unSetTestimonialDetail())
        dispatch(testimonialListing(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const getTestimonialDetail = (value) => async dispatch =>{
    try {
        const res = await callApi('post','/cms/testimonial/detail',value)
        dispatch(setTestimonialDetail(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const addTestimonial = (value) => async dispatch => {
  try {
      let res = await callApi('post','/cms/testimonial/add',value,{'Content-Type': 'multipart/form-data'})
      dispatch(apiSuccess(res.data))
  } catch (error) {
      dispatch(apiFail(error))
  }
};
export const updateTestimonial = (value) => async dispatch => {
    try {
        let res = await callApi('post','/cms/testimonial/update',value,{'Content-Type': 'multipart/form-data'})
        dispatch(apiSuccess(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
  };

