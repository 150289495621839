import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "../Helper/helper";
import { logout } from './authSlice'
const mainSlice = createSlice({
    name:'mainSlice',
    initialState:{
        success:null,
        failed:null,
        categoryList:{
            isLoading:true,
            data:[]
        }
    },
    reducers:{
        apiFailed:(state,action)=>{
            state.failed = action.payload 
        },
        unsetApiFailed:(state,action)=>{
            state.failed=null;
        },
        apiSucceed:(state,action)=>{
            state.success = action.payload 
        },
        unsetApiSucceed:(state,action)=>{
            state.success=null;
        },
        setCategoryList:(state,action)=>{
            state.categoryList = {
                isLoading:false,
                data:action.payload
            }
        }
    }
});
const { apiFailed, unsetApiFailed, apiSucceed, unsetApiSucceed, setCategoryList } =mainSlice.actions
export default mainSlice.reducer
export const apiSuccess = (value) => async dispatch => {
    dispatch(apiSucceed(value))
}
export const apiFail = (value) => async dispatch => {
    if (value.statusCode === 419) {
        dispatch(logout())   
    }else{
        dispatch(apiFailed(value))
    }
}
export const unSetApiFail = () => async dispatch =>{
    dispatch(unsetApiFailed())
}
export const unSetApiSucc = () => async dispatch =>{
    dispatch(unsetApiSucceed())
}
export const getCategoryList = () => async dispatch =>  {
    try {
        let res = await callApi('post','/service/categorylist',{})
        dispatch(setCategoryList(res.data.data))
    } catch (error) {
        dispatch(apiFail(error))
        
    }
}