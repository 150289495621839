import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

const Sidebar =({location,authUser})=> {
  const [activeSideBar, setActiveSideBar] = useState({})
  const dropdownPaths = [
    {path:'/user', state: 'userMenuOpen'},
    {path:'/vendor', state: 'vendorMenuOpen'},
    {path:'/category', state: 'categoryMenuOpen'},
    {path:'/service', state: 'serviceMenuOpen'},
    {path:'/form-elements', state: 'formElementsMenuOpen'},
    {path:'/tables', state: 'tablesMenuOpen'},
    {path:'/icons', state: 'iconsMenuOpen'},
  ];
  const toggleMenuState = (menuState) => {
    if (activeSideBar[menuState]) {
      setActiveSideBar({[menuState] : false});
    } else if(Object.keys(activeSideBar).length === 0) {
      setActiveSideBar({[menuState] : true});
    } else {
      Object.keys(activeSideBar).forEach(i => {
        setActiveSideBar({[i]: false});
      });
      setActiveSideBar({[menuState] : true});
    }
  }
  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  }
  useEffect(()=>{
    onRouteChanged();
    const body = document.querySelector('body');
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  },[])
  useEffect(()=>{
    onRouteChanged()
  },[location])
  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(activeSideBar).forEach(i => {
      setActiveSideBar({[i]: false});
    });
    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setActiveSideBar({[obj.state] : true})
      }
    }));
  }
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
            <div className="nav-profile-image">
              <img src={ authUser.data.pro_image } alt="profile" />
              <span className="login-status online"></span> {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2">{authUser.data.name.toUpperCase()}</span>
              {/* <span className="text-secondary text-small">Project Manager</span> */}
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li className={ isPathActive('/') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/">
            <span className="menu-title">Dashboard</span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        <li className={ isPathActive('/user') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.userMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('userMenuOpen') } data-toggle="collapse">
            <span className="menu-title">User Manager</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-circle menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.userMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/user/list') ? 'nav-link active' : 'nav-link' } to="/user/list">
                  List
                </Link>
              </li>
              <li className="nav-item"> 
                <Link className={ isPathActive('/user/add') ? 'nav-link active' : 'nav-link' } to="/user/add">
                  Add
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/vendor') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.vendorMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('vendorMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Vendor Manager</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-star menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.vendorMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/vendor/list') ? 'nav-link active' : 'nav-link' } to="/vendor/list">
                  List
                </Link>
              </li>
              <li className="nav-item"> 
                <Link className={ isPathActive('/vendor/add') ? 'nav-link active' : 'nav-link' } to="/vendor/add">
                  Add
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/category') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.categoryMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('categoryMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Category Manager</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-star menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.categoryMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/category/list') ? 'nav-link active' : 'nav-link' } to="/category/list">
                  List
                </Link>
              </li>
              <li className="nav-item"> 
                <Link className={ isPathActive('/category/add') ? 'nav-link active' : 'nav-link' } to="/category/add">
                  Add
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/service') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.serviceMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('serviceMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Service Manager</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-star menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.serviceMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> 
                <Link className={ isPathActive('/service/list') ? 'nav-link active' : 'nav-link' } to="/service/list">
                  List
                </Link>
              </li>
              <li className="nav-item"> 
                <Link className={ isPathActive('/service/add') ? 'nav-link active' : 'nav-link' } to="/service/add">
                  Add
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/cms') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/cms/list">
            <span className="menu-title">Cms List</span>
            <i className="mdi mdi-book menu-icon"></i>
          </Link>
        </li>
        {/* Theme Use */}
        {/* <li className={ isPathActive('/form-elements') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Form Elements</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.formElementsMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={ isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements">Basic Elements</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/tables') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Tables</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-table-large menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.tablesMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={ isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table">Basic Table</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={ isPathActive('/icons') ? 'nav-item active' : 'nav-item' }>
          <div className={ activeSideBar.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
            <span className="menu-title">Icons</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-contacts menu-icon"></i>
          </div>
          <Collapse in={ activeSideBar.iconsMenuOpen }>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={ isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi">Material</Link></li>
            </ul>
          </Collapse>
        </li> */}
      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);