import React from 'react'

const Spinner = () => {
  return (
    <div className="cusloader-contaner">
        <div className="custom-loader">
            <div className="table-wrap">
                <div className="align-wrap">
                    <div className="loader"></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Spinner
