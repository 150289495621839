import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const BasicTable = lazy(() => import('./tables/BasicTable'));
const Mdi = lazy(() => import('./icons/Mdi'));
const Error404 = lazy(() => import('./error-pages/Error404'));
// auth component 
const Login = lazy(() => import('./auth/Login'));
const ForgotPassword = lazy(() => import('./auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./auth/ResetPassword'));
const Profile = lazy(() => import('./auth/Profile'));
// User Component
const UserList = lazy(() => import('./user/List'))
const UserAdd = lazy(() => import('./user/Add'))
const UserEdit = lazy(() => import('./user/Edit'))
const UserView = lazy(() => import('./user/View'))
// Vendor Component
const VendorList = lazy(() => import('./vendor/List'))
const VendorAdd = lazy(() => import('./vendor/Add'))
const VendorEdit = lazy(() => import('./vendor/Edit'))
const VendorView = lazy(() => import('./vendor/View'))

// Category Component
const CategoryList = lazy(()=>import('./category/List'))
const CategoryAdd = lazy(()=>import('./category/Add'))
const CategoryEdit = lazy(()=>import('./category/Edit'))
// Service Component
const ServiceList = lazy(()=>import('./service/List'))
const ServiceAdd = lazy(()=>import('./service/Add'))
const ServiceEdit = lazy(()=>import('./service/Edit'))
// Service Addons
const ServiceAddOnes = lazy(()=>import('./service/Addons'))
// Cms Component
const CmsList = lazy(() => import('./cms/list'))
const CmsEdit = lazy(() => import('./cms/edit'))
const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <Switch>
        {/* auth route start */}
          <Route exact path="/login" component={ Login } />
          <Route exact path="/forgot" component={ ForgotPassword } />
          <Route exact path="/reset-password" component={ ResetPassword } />
          <Route exact path="/profile" component={ Profile } />
        {/* auth route end */}
          <Route exact path="/" component={ Dashboard } />
        {/* CMS Route start */}
          <Route exact path ="/cms/list" component={CmsList} />
          <Route path="/cms/:slug" component={CmsEdit} />
        {/* CMS Route end */}
        {/* User Route Start */}
          <Route exact path="/user/list" component={ UserList }/>
          <Route exact path="/user/add" component={ UserAdd }/>
          <Route path="/user/edit/:id" component={ UserEdit }/>
          <Route path="/user/view/:id" component={ UserView }/>
        {/* User Route End */}
        {/* Vendor Route Start */}
          <Route exact path="/vendor/list" component={ VendorList }/>
          <Route exact path="/vendor/add" component={ VendorAdd }/>
          <Route path="/vendor/edit/:id" component={ VendorEdit }/>
          <Route path="/vendor/view/:id" component={ VendorView }/>
        {/* Vendor Route End */}
        {/* Category Route Start */}
          <Route exact path="/category/list" component={ CategoryList } />
          <Route exact path="/category/add" component={ CategoryAdd } />
          <Route path="/category/edit/:id" component={ CategoryEdit } />
        {/* Category Route End */}
        {/* Service Route Start */}
          <Route exact path="/service/list" component={ ServiceList } />
          <Route exact path="/service/add" component={ ServiceAdd } />
          <Route path="/service/edit/:id" component={ ServiceEdit } />
          {/* Service Addons route start */}
            <Route path="/service/:id/addons" component={ ServiceAddOnes } />
          {/* Service Addons route end */}
        {/* Service Route End */}
        {/* Theme Route start */}
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />
          <Route path="/tables/basic-table" component={ BasicTable } />
          <Route path="/icons/mdi" component={ Mdi } />
        {/* not found page  */}
          <Route path="/404" component={ Error404 } />
          <Redirect from='*' to='/404' />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;