import { createSlice } from "@reduxjs/toolkit";
import {callApi} from '../Helper/helper'
import { apiFail,apiSuccess } from './mainSlice'

const serviceSlice = createSlice({
    name:"Service",
    initialState:{
        list:null,
        detail:{
            categoryId: "",
            title: "",
            cost: "",
            optionType: "",
            selectOption:[],
            description: "",
            status:""
        },
        addOnes:{
            loading:true,
            data:[]
        }
    },
    reducers:{
        listing:(state,action)=>{
            state.list = action.payload.data
        },
        setDetail:(state,action) =>{
            state.detail =action.payload.data
        },
        unSetDetail:(state,action)=>{
            state.detail = {
                categoryId: "",
                title: "",
                cost: "",
                optionType: "",
                selectOption:[],
                description: "",
                status:""
            }
        },
        setAddOnsList:(state,action)=>{
            state.addOnes = {
                loading:false,
                data:action.payload
            }
        },
        unSetAddOnsList:(state,action)=>{
            state.addOnes = {
                loading:true,
                data:[]
            }
        }
    }
})

export default serviceSlice.reducer
const { listing,setDetail,unSetDetail,setAddOnsList,unSetAddOnsList } = serviceSlice.actions

export const getList = (page,filter) => async dispatch =>{
    try {
        let value = Object.assign({},{page:page},filter)
        const res = await callApi('post','/service/list',value)
        dispatch(unSetDetail())
        dispatch(unSetAddOnsList())
        dispatch(listing(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const addService = (value) => async dispatch =>{
    try {
        const res = await callApi('post','/service/add',value,{'Content-Type': 'multipart/form-data'})
        dispatch(apiSuccess(res.data))
    } catch (e) {
        dispatch(apiFail(e))
    } 
}
export const getEditService = (value) => async dispatch => {
    try {
        let res = await callApi('post','/service/edit',value)
        dispatch(setDetail(res.data))
    } catch (error) {
        dispatch(apiFail(error))
    }
}
export const updateService = (value) => async dispatch => {
  try {
      let res = await callApi('post','/service/update',value,{'Content-Type': 'multipart/form-data'})
      dispatch(apiSuccess(res.data))
  } catch (error) {
      dispatch(apiFail(error))
  }
};
/* add ons Function start */
    export const getAddOnsList = (value) => async dispatch => {
        try {
            let res = await callApi('post','/service/addons/list',value)
            dispatch(setAddOnsList(res.data.data))
        } catch (error) {
            dispatch(apiFail(error))
        }
    }
    export const createAddOns = (value) => async dispatch => {
        try {
            let res = await callApi('post','/service/addons/add',value)
            dispatch(apiSuccess(res.data))
        } catch (error) {
            dispatch(apiFail(error))
        }
    }
    export const updateAddOns = (value) => async dispatch => {
        try {
            let res = await callApi('post','/service/addons/update',value)
            dispatch(apiSuccess(res.data))
        } catch (error) {
            dispatch(apiFail(error))
        }
    }
/* add ons Function end */

